import { Button } from "antd"
import { Link } from "gatsby"
import React from "react"
import CurveContainer from "../CurveContainer/CurveContainer"
import { TextTypography } from "../SharedResources/Typography/TextTypography/TextTypography"
import {
  FontStyle,
  TextColor,
} from "../SharedResources/Typography/typography.service"

import "./Values.scss"
import allJars from "../../images/allJars.png"
import botsJar from "../../images/botsJar.png"
import spiesJar from "../../images/spiesJar.png"
import cookiesJar from "../../images/cookiesJar.png"

const Values: React.FC = () => {
  return (
    <section>
      <article className="mt-16  mb-40 md:mb-60">
        <CurveContainer>
          <div className="flex flex-col container mx-auto">
            <TextTypography
              className="font-semibold text-3xl sm:text-5xl"
              color={TextColor.WHITE}
            >
              Privacy on purpose
            </TextTypography>
            <TextTypography
              className="mt-8 text-xl sm:text-3xl"
              fontStyle={FontStyle.ITALIC}
              color={TextColor.WHITE}
            >
              You maintain control of your data
            </TextTypography>
            <img src={allJars} alt="all Jars" className="-mb-48 mt-8" />
          </div>
        </CurveContainer>
      </article>
      <article className="flex flex-col-reverse md:flex-row md:space-x-24 container mx-auto md:mt-20 items-center space-y-reverse  md:space-y-0">
        <div className="cookies-jar-image-container web-images">
          <img src={cookiesJar} alt="cookies Jar" />
        </div>

        <div>
          <TextTypography className="text-3xl sm:text-5xl h-full ">
            Dome has no cookies for you
          </TextTypography>
          <div className="cookies-jar-image-container mobile-images">
            <img src={cookiesJar} alt="cookies Jar" />
          </div>
          <div className="mt-4">
            <TextTypography className="text-base">
              Dome has no cookies for you. We only have a single set of cookies
              but it never tracks you.
            </TextTypography>
          </div>
          <div className="mt-4">
            <TextTypography className="text-base">
              This single set of cookies connects to our internal software to
              verify that Dome stays in compliance with privacy laws throughout
              the United States.
            </TextTypography>
          </div>
        </div>
      </article>

      <article className="flex flex-col md:flex-row md:space-x-16 container mx-auto mt-20  md:items-center  md:space-y-0">
        <div className="md:px-24">
          <TextTypography className="text-3xl sm:text-5xl h-full">
            Dome has zero bots
          </TextTypography>
          <div className="w-60 md:flex-shrink-0 flex mx-auto mobile-images">
            <img src={botsJar} alt="bots Jar" />
          </div>
          <div className="mt-4">
            <TextTypography className="text-base">
              That’s right, we’ve designed the platform to prevent bots from
              registering. Each user is verified.
            </TextTypography>
          </div>
        </div>
        <div className="w-60 md:flex-shrink-0 flex mx-auto web-images">
          <img src={botsJar} alt="bots Jar" />
        </div>
      </article>

      <article className="flex flex-col-reverse md:flex-row md:space-x-16 container mx-auto mt-20 items-center space-y-reverse  md:space-y-0">
        <div className="spies-jar-image-container web-images">
          <img src={spiesJar} alt="spies Jar" />
        </div>
        <div>
          <TextTypography className="text-3xl sm:text-5xl h-full ">
            Spies, trolls, and trackers are banned
          </TextTypography>
          <div className="spies-jar-image-container mobile-images">
            <img src={spiesJar} alt="spies Jar" />
          </div>
          <div className="mt-4">
            <TextTypography className="text-base">
              Spies, trolls, and trackers all need tools and tricks inside
              software in order to function. That’s why we did not build any of
              the features they need.
            </TextTypography>
          </div>
        </div>
      </article>
      <article className="bg-no-repeat bg-cover bg-container p-5 pb-72 mt-32 ">
        <div className="flex flex-col container items-center mx-auto mt-16">
          <TextTypography
            className="text-3xl md:text-5xl text-center"
            fontStyle={FontStyle.ITALIC}
          >
            Dome is launching in stages. At first, we will be an easy-to-use
            calendar of free policy events in Washington and available online
          </TextTypography>
          <div className="pb-24">
            <Link to={"/apply-now/#plans"}>
              <Button className="bg-white py-3 px-16 rounded-lg mt-14 h-auto border border-primary">
                <TextTypography
                  className="text-base h-full w-full"
                  fontStyle={FontStyle.BOLD}
                  color={TextColor.PRIMARY}
                >
                  Get Started
                </TextTypography>
              </Button>
            </Link>
          </div>
        </div>
      </article>
    </section>
  )
}
export default Values
