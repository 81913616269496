import React from "react"
import AppFooter from "../components/AppFooter/AppFooter"
// import AppHeader from "../components/AppHeader/AppHeader"
import AppHeader from "../components/V2/AppHeader"
import FaviconHeader from "../components/FaviconHeader/FaviconHeader"
import Values from "../components/Values/Values"

const ValuesPage: React.FC = () => {
  return (
    <main className="w-full bg-lightBackground">
      <FaviconHeader />
      {/* <AppHeader className="transparent-bg-color" /> */}
      <AppHeader />
      <Values />
      <div className="-mt-80">
        <AppFooter />
      </div>
    </main>
  )
}

export default ValuesPage
